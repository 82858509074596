import React, { useEffect } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderData = useSelector(state => state.order);
  const isDarkMode = useSelector(state => state.app.isDarkMode);

  useEffect(() => {
    updateData("fileList", []);
    updateData("promo1", false);
    updateData("promo2", false);
    updateData("note", "");
    updateData("orderId", "");
    updateData("coupon", "");
    updateData("delivery", "free");
    window.scrollTo(0, 0);
  }, []);

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  return (
    <PublicLayout>
      <img src="https://fixphotos.ai/split-test-for-elementor/v1/tests/2/track-conversion/" alt="" className='w-0 h-0' />
      <img src="https://fixphotos.ai/split-test-for-elementor/v1/tests/4/track-conversion/" alt="" className='w-0 h-0' />
      <div className='text-center m-5'>
        <h1 className={classNames('text-2xl max-w-lg mx-auto p-5 rounded', isDarkMode ? "bg-gray-900" : "bg-orange-100")}>Success your photo has been sent to our restoration experts, please be patient while they work on your photo.</h1>
        <Button className='w-72' type='primary' size='large' onClick={() => {
          navigate('/order/step1');
        }}>Upload New Photo</Button>
      </div>
    </PublicLayout>
  )
}

export default Success;