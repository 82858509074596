import React, { useEffect, useState } from 'react'
import classNames from 'classnames';

// import { Button, Input } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

function NumberInput({
  isDarkMode,
  initialValue,
  value,
  onChange,
  min,
}) {

  const [_value, setValue] = useState(value || (initialValue || (min || 0)));

  useEffect(() => {
    if (value && Number.isInteger(Number(value)) && Number(value) >= (min || 0)) {
      setValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange(_value);
    }
  }, [_value]);


  const handleChange = (e) => {
    // console.log(Number.isInteger(Number(e.target.value)), e.target.value);
    if (Number.isInteger(Number(e.target.value)) && Number(e.target.value) >= 0) {
      setValue(Number(e.target.value));
    }
  }

  const handleIncrese = (delta) => {
    setValue(prev => {
      if (prev + delta >= (min || 0)) {
        return prev + delta;
      }
      return prev;
    });
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 38) {
      // Up
      handleIncrese(1);
    } else if (e.keyCode === 40) {
      // Down
      handleIncrese(-1);
    }
  }

  return (
    <div className='inline-flex items-center flex-col md:flex-row h-'>
      <button
        className={classNames('w-[26px] h-[26px] select-none shadow-md transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none border-none cursor-pointer rounded ', isDarkMode ? 'bg-gray-600' : 'bg-gray-300 text-white')}
        onClick={() => handleIncrese(-1)}
      ><MinusOutlined /></button>
      <input className='border-none focus:boder-none focus:outline-none bg-transparent text-center w-12 font-bold' value={_value} onChange={handleChange} onKeyUp={handleKeyUp} />
      <button
        className={classNames('w-[26px] h-[26px] select-none shadow-md transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none border-none cursor-pointer rounded', isDarkMode ? 'bg-gray-600' : 'bg-gray-300 text-white')}
        onClick={() => handleIncrese(1)}
      ><PlusOutlined /></button>
    </div>
  )
}

export default NumberInput;