import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Divider, Input, Row, Upload, message } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { PiArrowFatLeftFill, PiArrowFatRightFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import NumberInput from '../../../components/NumberInput';
import PublicLayout from '../../layouts/PublicLayout';
import { CheckCircleFilled, CheckCircleTwoTone, CloseCircleFilled } from '@ant-design/icons';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import Header from './Partials/Header';
import abg from "../../../assets/images/after-slider-bg.jpg";
import dbg from "../../../assets/images/bg.webp";
import pbg from "../../../assets/images/print-bg.jpg";
import constants from '../../../config/constants';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { saveOrder } from '../../../services/orderAPI';

const { Dragger } = Upload;

function Step3() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDarkMode = useSelector(state => state.app.isDarkMode);
  const orderData = useSelector(state => state.order);

  const [shippingDelay, setShippingDelay] = useState(orderData.shippingDelay);
  const [promo1, setPromo1] = useState(orderData.promo1);
  const [promo2, setPromo2] = useState(orderData.promo2);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateData('shippingDelay', shippingDelay);
  }, [shippingDelay]);

  useEffect(() => {
    updateData('promo1', promo1);
  }, [promo1]);

  useEffect(() => {
    updateData('promo2', promo2);
  }, [promo2]);

  useEffect(() => {
    window.scrollTo(0, 0)
    updateData('step', 2);
  }, []);

  useEffect(() => {
    if (orderData.fileList.length == 0) {
      message.warning('Please upload your photos!');
      navigate('/order/step2');
    }
  }, [orderData]);


  const updateData = (field, value) => {
    dispatch(updateOrderDetail({
      field,
      value,
    }));
  };

  return (
    <PublicLayout>
      <Header />
      <div className="max-w-4xl mx-auto w-full p-8 sm:p-4 text-center">
        <section className='my-4'>
          <Row gutter={[24, 24]} align={"center"} className='bg-white rounded-2xl shadow-lg'>
            <Col xs={24} md={12} className='!pl-0 !pr-0 md:!pr-2'>
              <div className='text-left rounded-2xl overflow-clip bg-cover p-8' style={{
                backgroundImage: `url('${abg}')`,
              }}>
                <ImgComparisonSlider hover className='slider-split-line rounded-2xl w-full'>
                  <figure slot="first" className="before">
                    <img className='w-full' src={`/imgs/before.webp`} alt="before" />
                    {/* <figcaption>Before</figcaption> */}
                  </figure>
                  <figure slot="second" className="after">
                    <img className='w-full' src={`/imgs/after.webp`} alt="after" />
                    {/* <figcaption>After</figcaption> */}
                  </figure>
                  <svg slot="handle" className="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                    <path stroke="#000" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" strokeWidth="1" fill="#fff" vectorEffect="non-scaling-stroke"></path>
                  </svg>
                </ImgComparisonSlider>
                <h1 className='text-xl md:text-2xl mt-4 text-white'>Turn Black & White into Color!</h1>
                <h1 className='text-xl md:text-2xl lg:text-3xl text-orange-500'>+$9.99 per photo</h1>
                <p className='text-white'>If your photo is faded, discolored or in black & white, and you would like this photo colorized, please select this option.</p>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col justify-center h-full overflow-auto">
                {orderData.fileList.map((file, index) => <div className='py-2 md:p-4 border-0 border-solid border-gray-200 border-y' key={index}>
                  {file.path ? <img src={`${constants.SOCKET_URL}${file.path}`} className='w-16 h-16 rounded shadow object-cover mr-4' alt="" /> : <div className='w-16 h-16 bg-gray-200 rounded shadow items-center justify-center mr-4 inline-flex'>
                    <h1 className="font-bold text-purple-600 m-0">{index + 1}</h1>
                  </div>}
                  <Button onClick={() => {
                    const files = orderData.fileList.map(file => ({ ...file }));
                    files[index].colorize = true;
                    updateData('fileList', files);
                  }} shape='round' type='primary' className='mr-2' icon={<CheckCircleFilled style={{ color: file.colorize ? 'orange' : '#888' }} />}>Yes Please</Button>
                  <Button onClick={() => {
                    const files = orderData.fileList.map(file => ({ ...file }));
                    files[index].colorize = false;
                    updateData('fileList', files);
                  }} shape='round' icon={<CloseCircleFilled style={{ color: file.colorize ? '#888' : 'red' }} />}>No Thanks</Button>
                </div>)}
              </div>
            </Col>
          </Row>
        </section>
        <section className='my-16 text-left'>
          <Row gutter={[24, 24]} className='bg-cover rounded-2xl p-6 relative' style={{
            backgroundImage: `url('${dbg}')`
          }}>
            <div className="absolute top-0 left-0 w-full h-full bg-[#000a] rounded-2xl"></div>
            <Col xs={24} md={12}>
              <img src="/imgs/print.webp" className='w-full rounded-2xl' alt="" />
            </Col>
            <Col xs={24} md={12}>
              <div className='h-full flex flex-col justify-around p-0 sm:p-4'>
                <h1 className='text-xl md:text-3xl text-white mt-0'>Please Choose Your Prints</h1>
                <h1 className='text-xl md:text-2xl lg:text-3xl text-orange-500'><img src="/imgs/shipping-icon.svg" className='mr-2' alt="" />FREE Shipping</h1>
                <p className='text-white'>We print your photo on museum-quality glossy C-TYPE Silver Halide photo paper that is the highest-quality print possible, and will make your photos look brilliant.</p>
              </div>
            </Col>
          </Row>
          {/* <Row gutter={[24, 24]} className={classNames('mt-16 p-6 rounded-2xl', isDarkMode ? 'bg-gray-900' : 'bg-white')}>
            <Col xs={24} md={12}>
              <div className={classNames('flex px-4 flex-wrap rounded-2xl p-6', isDarkMode ? 'bg-gray-600' : 'bg-purple-50')}>
                <div className='w-[60%] py-4'>
                  <span className='bg-teal-600 px-4 py-1 rounded-full font-bold text-white'>SPECIAL PROMO</span>
                  <h1 className='mt-6'>Print Package</h1>
                  <h1><span className="text-orange-500 font-bold">$29</span> <span className='text-gray-300 line-through font-bold'>$54</span> per photo</h1>
                  <h4 className="inline px-2 bg-white rounded-full text-gray-700"><span className="text-orange-500">$58</span> total for your order</h4>
                  <br />
                  <br />
                  <small>A set of <b>1-8x10</b> & <b>2-5x7</b> archival photo prints for each photo.</small>
                </div>
                <div className='w-[40%]'>
                  <img src="/imgs/Print_Package_NEW-min-1.png" className='w-full' alt="" />
                </div>
                <div className='w-full'>
                  <Button onClick={() => setPromo1(true)} shape='round' type='primary' className='mr-2' icon={<CheckCircleFilled style={{ color: promo1 ? 'orange' : '#888' }} />}>Yes Please</Button>
                  <Button onClick={() => setPromo1(false)} shape='round' icon={<CloseCircleFilled style={{ color: promo1 ? '#888' : 'red' }} />}>No Thanks</Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={classNames('flex px-4 flex-wrap rounded-2xl p-6', isDarkMode ? 'bg-gray-600' : 'bg-purple-50')}>
                <div className='w-[60%] py-4'>
                  <span className='bg-teal-600 px-4 py-1 rounded-full font-bold text-white'>SPECIAL PROMO</span>
                  <h1 className='mt-6'>Wallet Size Print</h1>
                  <h1><span className="text-orange-500 font-bold">$19</span> <span className='text-gray-300 line-through font-bold'>$26</span> per photo</h1>
                  <h4 className="inline px-2 bg-white rounded-full text-gray-700"><span className="text-orange-500">$38</span> total for your order</h4>
                  <br />
                  <br />
                  <small>Add a set of <b>8 wallet-sized prints</b> for each photo</small>
                </div>
                <div className='w-[40%]'>
                  <img src="/imgs/Wallet_Size_NEW-min-1.png" className='w-full' alt="" />
                </div>
                <div className='w-full'>
                  <Button onClick={() => setPromo2(true)} shape='round' type='primary' className='mr-2' icon={<CheckCircleFilled style={{ color: promo2 ? 'orange' : '#888' }} />}>Yes Please</Button>
                  <Button onClick={() => setPromo2(false)} shape='round' icon={<CloseCircleFilled style={{ color: promo2 ? '#888' : 'red' }} />}>No Thanks</Button>
                </div>
              </div>
            </Col>
          </Row> */}
        </section>

        <section className="my-16">
          <Row gutter={[24, 24]} className={classNames('rounded-2xl pt-6', isDarkMode ? 'bg-gray-700' : 'bg-white')}>
            <Col span={24}>
              <h1 className='text-xl md:text-xl'>
                Would you like to add museum-quality, photo prints to your order?<br />
                Select exactly how many you would like for each photo.
              </h1>
              <p className="text-md">Select exactly how many you would like for each photo.</p>
            </Col>
            <Col span={24}>
              <div className="overflow-y-auto">
                <table className='w-full'>
                  <thead>
                    <tr className='border-0 border-b border-solid border-b-gray-400'>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4 border-b-1 border-b-solid border-gray-400'></th>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4'>
                        <h3 className='sm:font-bold'>4x6 Print</h3>
                        <small>$10 per photo</small>
                      </th>
                      <th className={classNames('w-1/6 p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                        <h3 className='sm:font-bold'>5x7 Print</h3>
                        <small>$15 per photo</small>
                      </th>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4'>
                        <h3 className='sm:font-bold'>8x10 Print</h3>
                        <small>$24 per photo</small>
                      </th>
                      <th className={classNames('w-1/6 p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                        <h3 className='sm:font-bold'>11x14 Print</h3>
                        <small>$34 per photo</small>
                      </th>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4'>
                        <h3 className='sm:font-bold'>16x20 Print</h3>
                        <small>$49 per photo</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      orderData.fileList.map((file, index) =>
                        <tr key={index}>
                          <td className='p-1 sm:p-2 md:p-4'>
                            {file.path ? <img src={`${constants.SOCKET_URL}${file.path}`} className='w-14 h-14 rounded object-cover' alt="" /> : <div className='w-14 h-14 bg-gray-200 rounded shadow flex items-center justify-center'>
                              <h1 className="font-bold text-purple-600 m-0">{index + 1}</h1>
                            </div>}
                          </td>
                          <td className='p-1 sm:p-2 md:p-4'>
                            <NumberInput isDarkMode={isDarkMode} value={file.print1} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print1 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className={classNames('p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                            <NumberInput isDarkMode={isDarkMode} value={file.print2} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print2 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className='p-1 sm:p-2 md:p-4'>
                            <NumberInput isDarkMode={isDarkMode} value={file.print3} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print3 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className={classNames('p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                            <NumberInput isDarkMode={isDarkMode} value={file.print4} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print4 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className='p-1 sm:p-2 md:p-4'>
                            <NumberInput isDarkMode={isDarkMode} value={file.print5} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print5 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </section>

        {/* <section className="my-16 text-white">
          <Row gutter={[24, 24]} className='bg-cover rounded-2xl py-6 relative' align={"center"} style={{
            backgroundImage: `url('${dbg}')`
          }}>
            <div className="absolute top-0 left-0 w-full h-full bg-[#000a] rounded-2xl"></div>
            <Col md={20} xs={24}>
              <h1 className='text-xl md:text-3xl font-bold'>
                Want to add frames for your prints?
              </h1>
              <p className="text-md md:text-xl font-gray-400 mb-0">You can browse our selection of frames and find the perfect one to complete your prints after you approve the digital finished versions of your photos. Continue below for now!</p>
            </Col>
          </Row>
        </section> */}

        <section className="my-16 text-white">
          <Row gutter={[24, 24]} className='bg-cover rounded-2xl py-6 relative' align={"center"} style={{
            backgroundImage: `url('${dbg}')`
          }}>
            <div className="absolute top-0 left-0 w-full h-full bg-[#000a] rounded-2xl"></div>
            <Col md={20} xs={24}>
              <h1 className='text-xl md:text-3xl font-bold'>
                How quickly would you like to receive your restorations?
              </h1>
              <p className="text-md md:text-xl font-gray-400 mb-0">We deliver from Monday to Saturday.</p>
            </Col>
          </Row>
        </section>

        <section className="my-16">
          <Row gutter={[24, 24]} align={'center'}>
            <Col xs={24} md={8}>
              <Card onClick={() => setShippingDelay(0)} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (shippingDelay == 0 && isDarkMode) && 'border-purple-700 bg-gray-900', (shippingDelay == 0 && !isDarkMode) && "border-purple-700 bg-purple-50", (shippingDelay != 0 && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                <div className='absolute top-5 left-5 md:left-auto md:right-5'><CheckCircleTwoTone className='text-lg' twoToneColor={shippingDelay == 0 ? '#0F0' : '#AAA'} /></div>
                <div className='flex flex-col justify-between items-center h-full pt-4'>
                  <img src="/imgs/delivery-motorbike-svgrepo-com.svg" className='w-12 sm:w-14 md:w-16' alt="" />
                  <h1 className={classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', shippingDelay == 0 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>3 Days</h1>
                  <h2 className='text-sm sm:text-lg md:text-xl text-orange-500'>NO EXTRA CHARGE</h2>
                  <p className='font-bold'>{dayjs().add(3, 'day').format('dddd MMMM D')}</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card onClick={() => setShippingDelay(1)} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (shippingDelay == 1 && isDarkMode) && 'border-purple-700 bg-gray-900', (shippingDelay == 1 && !isDarkMode) && "border-purple-700 bg-purple-50", (shippingDelay != 1 && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                <div className='absolute top-5 left-5 md:left-auto md:right-5'><CheckCircleTwoTone className='text-lg' twoToneColor={shippingDelay == 1 ? '#0F0' : '#AAA'} /></div>
                <div className='absolute w-44 top-0 -translate-x-1/2 -translate-y-1/2 left-[50%] text-sm font-bold text-white bg-purple-700 py-0.5 rounded-full'>MOST POPULAR</div>
                <div className='flex flex-col justify-between items-center h-full pt-4'>
                  <img src="/imgs/airplane-delivery-svgrepo-com.svg" className='w-16 sm:w-14 md:w-20' alt="" />
                  <h1 className={classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', shippingDelay == 1 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>24 Hours</h1>
                  <h2 className='text-sm sm:text-lg md:text-xl text-orange-500'>$9.99 PER PHOTO</h2>
                  <p className='font-bold'>{dayjs().add(1, 'day').format('dddd MMMM D')}</p>
                </div>
              </Card>
            </Col>
            {/* <Col xs={24} md={8}>
              <Card onClick={() => setShippingDelay(2)} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (shippingDelay == 2 && isDarkMode) && 'border-purple-700 bg-gray-900', (shippingDelay == 2 && !isDarkMode) && "border-purple-700 bg-purple-50", (shippingDelay != 2 && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                <div className='absolute top-5 left-5 md:left-auto md:right-5'><CheckCircleTwoTone className='text-lg' twoToneColor={shippingDelay == 2 ? '#0F0' : '#AAA'} /></div>
                <div className='flex flex-col justify-between items-center h-full pt-4'>
                  <img src="/imgs/delivery3.svg" className='w-12 sm:w-14 md:w-16' alt="" />
                  <h1 className={classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', shippingDelay == 2 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>24 Hours</h1>
                  <h2 className='text-sm sm:text-lg md:text-xl text-orange-500'>$19.97 PER PHOTO</h2>
                  <p className='font-bold'>{dayjs().add(1, 'day').format('dddd MMMM D')}</p>
                </div>
              </Card>
            </Col> */}
          </Row>
        </section>

        <section className="my-16">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <h1 className='max-w-2xl mx-auto text-xl md:text-3xl font-bold'>Is there anything you would like your artist to know?<span className='text-gray-400 text-md md:text-xl'>(Optional)</span></h1>
            </Col>
            <Col span={24}>
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 5 }}
                size="large"
                placeholder="Enter your notes here"
                value={orderData.note}
                onChange={(e) => updateData('note', e.target.value)}
              />
            </Col>
          </Row>
        </section>

        <section className='mt-4 mb-8'>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <div className="flex items-center justify-center flex-wrap">
                <Button className='w-full sm:w-44 m-2 inline-flex items-center justify-center' size='large' onClick={() => {
                  updateData('step', 1);
                  navigate('/order/step2');
                }}><PiArrowFatLeftFill /> &nbsp; Back</Button>
                <Button onClick={() => {
                  setLoading(true);
                  saveOrder({ orderData }).then(res => {
                    // console.log(res);
                    updateData("orderId", res.data.orderId);
                    navigate('/order/checkout');
                  }).catch(err => {
                    console.log(err);
                  }).finally(() => {
                    setLoading(false);
                  });
                }} className='w-full sm:w-96 m-2 inline-flex items-center justify-center' size='large' type='primary' loading={loading}>Click to go to Checkout &nbsp; <PiArrowFatRightFill /></Button>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </PublicLayout>
  )
}

export default Step3;