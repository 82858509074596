import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Badge, Button, Upload, message, Divider, Input, Modal, Radio, Tabs, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { debounce } from 'lodash';
import { FaThumbsUp } from 'react-icons/fa6';
import { IoMdPrint } from "react-icons/io";
import { PiFrameCornersLight } from 'react-icons/pi';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import { DownloadOutlined, LockOutlined, MailOutlined, UploadOutlined } from '@ant-design/icons';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import constants, { ORDER_STATUS, PHOTO_STATE, PHOTO_STATUS, PRINT_SIZES } from '../../../config/constants';
import { getStorage, setStorage } from '../../../helpers';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { /* analyzePhoto, */ approveResult, getOrdersById, printingPackage, savePhotoDir, savePrintSetting, updateRequest } from '../../../services/orderAPI';

import 'react-image-crop/dist/ReactCrop.css'

const Result = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [note, setNote] = useState({});
  const [loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [isPrStModalOpen, setIsPrStModalOpen] = useState(false);
  const [landscape, setLandscape] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pSetting, setPSetting] = useState([]);
  const [crp, setCrp] = useState([]);
  const [selFile, setSelFile] = useState({});
  const [user, setUser] = useState({});
  // const user = useSelector(state => state.auth.user);

  const getOrder = async () => {
    try {
      let res = await getOrdersById(id);
      setOrder({ ...res.data.order });
      setUser(res.data.user);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    updateData("fileList", []);
    updateData("promo1", false);
    updateData("promo2", false);
    updateData("note", "");
    updateData("orderId", "");
    getOrder();
  }, []);

  useEffect(() => {
    // console.log(order);
  }, [order]);

  const handleDownload = (url, index) => {
    saveAs(url, `photo_${id}_${index}.jpg`);
  }

  const handleClick = async (file, index) => {
    if (!order.paymentId) {
      // setStorage('prev_url', id);
      const { _id, createdAt, paymentId, refunded, status, updatedAt, userId, __v, ...rest } = order;
      dispatch(updateOrderDetail({
        value: {
          orderId: _id,
          name: userId.name,
          email: userId.email,
          ...rest
        }
      }))
      navigate('/order/checkout');
    } else {
      handleDownload(`${constants.SOCKET_URL}${file.result}`, index);
    }
  }

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  const isShipped = (file) => {
    // console.log(file);
    return file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0;
  }

  const packagePrint = () => {
    return order.promo1 || order.promo2;
  }

  const previewImage = (i) => {
    let se = crp[i];
    if (!se) return `${constants.SOCKET_URL}${selFile.result}`;
    let x = se.x;
    let y = se.y;
    let w = se.width;
    let h = se.height;
    let scale = se.scale;
    let searchParams = new URLSearchParams({ x, y, w, h, scale });

    return `${constants.SOCKET_URL}${selFile.result}?${searchParams}`;
  }

  const handleWheel = debounce((e, i) => {
    let tmp = [...crp];
    // console.log(e, e.deltaY, tmp[i].scale);
    tmp[i] = { ...tmp[i], scale: (tmp[i].scale ? tmp[i].scale : 1) + ((e.deltaY < 0 ? 0.01 : -0.01)) };
    if (tmp[i].scale > 1) {
      tmp[i].scale = 1;
    } else if (tmp[i].scale <= 0.3) {
      tmp[i].scale = 0.3;
    }
    setCrp(tmp);
    // e.preventDefault();
  });

  const openPrintSettingModal = (file, index) => {
    // console.log(file);
    let tmp = [];
    // let bi = file.dimension.w / file.dimension.h;
    setLandscape(file.dimension.landscape);
    for (const i of [1, 2, 3, 4, 5]) {
      if (file[`print${i}`] > 0) {
        // console.log(`print${i}`, file[`print${i}`])
        tmp.push({
          ...centerCrop(
            makeAspectCrop(
              {
                // You don't need to pass a complete crop into
                // makeAspectCrop or centerCrop.
                unit: '%',
                width: 100,
              },
              (Number(PRINT_SIZES[`print${i}`].split('x')[file.dimension.landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${i}`].split('x')[file.dimension.landscape ? 0 : 1])),
              file.dimension.w,
              file.dimension.h
            ),
            file.dimension.w,
            file.dimension.h
          ),
          paper: `print${i}`,
          count: file[`print${i}`]
        });
      }
    }
    setCrp(pre => tmp.map((item, i) => (file.print[i] ? ({ ...item, ...file.print[i] }) : item)));
    setPSetting([...tmp.map((item, i) => (file.print[i] ? ({ ...item, ...file.print[i] }) : item))]);
    setSelFile({ ...file, orderId: order._id, index: index });
    setIsPrStModalOpen(true);
  }
/* 
  useEffect(() => {
    console.log(crp);
  }, [crp]); */

  return (
    <PublicLayout>
      <div className='p-4'>
        <h1 className="text-center text-2xl">
          {/* <Popconfirm
            title="Confirm"
            description="Are you sure to print this order?"
            onConfirm={() => {
              if ((order.status == ORDER_STATUS.PendingApprove && !order.fileList.filter(file => !(file.refunded > 0)).some(file => file.approved < PHOTO_STATE.Printing))) {
                setPrinting(true);
                printingPackage(order._id).then(res => {
                  getOrder();
                }).catch(err => {
                  console.log(err);
                  if (err.response?.data?.message)
                    message.warning(err.response.data.message);
                }).finally(() => {
                  setPrinting(false);
                });
              } if (order.status >= ORDER_STATUS.Printing) {
                message.warning("Please wait until printing is done!");
              } else {
                message.warning("Order is not ready to print!");
              }
            }}
            onCancel={() => { }}
          >
            <Button icon={<IoMdPrint />} loading={printing} className='mr-2 inline-flex items-center justify-center' size="large" onClick={() => {
            }}>Print Now</Button>
          </Popconfirm> */}
        </h1>
        <Row gutter={32} justify={'center'} align={"stretch"}>
          {order ? order.fileList?.map((file, index) => <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <div className="flex flex-col justify-between h-full">
              <Badge.Ribbon color="#CB2B8377" text={<div className='py-1'>
                {file.colorize && <p className='m-0 leading-none'><span className="font-bold">&#9679; Colorize</span></p>}
                {(!isShipped(file) && !packagePrint()) && <p className='m-0 leading-none'><span className="font-bold">&#9679; No Print</span></p>}
                {order.promo1 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print Package(1-8x10 & 2-5x7):</span> Yes</p>}
                {order.promo2 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Wallet Size Print:</span> Yes</p>}
                {file.print1 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 4x6:</span> {file.print1}</p>}
                {file.print2 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 5x7:</span> {file.print2}</p>}
                {file.print3 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 8x10:</span> {file.print3}</p>}
                {file.print4 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 11x14:</span> {file.print4}</p>}
                {file.print5 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 16x20:</span> {file.print5}</p>}
              </div>}><ImgComparisonSlider className='slider-split-line'>
                  <figure slot="first" className="h-full before">
                    <img className='w-full h-full' src={`${constants.SOCKET_URL}${file.path}`} alt="before" />
                    <figcaption>Before</figcaption>
                  </figure>
                  <figure slot="second" className="h-full after">
                    <img className='w-full h-full' src={`${constants.SOCKET_URL}${file.result}`} alt="after" />
                    <figcaption>After</figcaption>
                  </figure>
                  <svg slot="handle" className="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                    <path stroke="#000" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" strokeWidth="1" fill="#fff" vectorEffect="non-scaling-stroke"></path>
                  </svg>
                </ImgComparisonSlider></Badge.Ribbon>
              <div className='text-center mt-4'>
                {(isShipped(file) || packagePrint()) && <>
                  <Button icon={<FaThumbsUp />} block size="large" className='mb-2' disabled={file.approved >= PHOTO_STATE.Approved} type='primary' onClick={() => {
                    approveResult(id, { index }).then(async res => {
                      message.success('Successfully approved!');
                      await getOrder();
                      openPrintSettingModal(file, index);
                    }).catch(err => {
                      message.warning("Something went wrong!");
                    })
                  }}>
                    {file.approved > 1 ? (file.approved === PHOTO_STATE.Printing && order.status == ORDER_STATUS.PendingApprove ? "Printing" : PHOTO_STATUS[file.approved]) : "Approve for Print"}
                  </Button>
                  <Button type='primary' block size="large" icon={(!order.paymentId) ? <LockOutlined /> : <DownloadOutlined />} onClick={() => handleClick(file, index)}>
                    {(!order.paymentId) ? "Purchase to remove watermark" : "Download High Resolution"}
                  </Button>
                  {file.approved >= PHOTO_STATE.Approved && <Button type='primary' className='my-2 flex items-center justify-center' block size="large" icon={<IoMdPrint />} onClick={() => {
                    openPrintSettingModal(file, index);
                  }}>
                    Print Settings
                  </Button>}
                  <Divider>Any Issues?</Divider>
                  <Input.TextArea
                    size='large'
                    placeholder="Please describe what needs to be fixed"
                    autoSize={{
                      maxRows: 5,
                      minRows: 2
                    }}
                    value={note[index]}
                    disabled={file.approved > 1}
                    onChange={e => setNote(prev => ({
                      ...prev,
                      [index]: e.target.value
                    }))}
                  />
                  <Button
                    block
                    size="large"
                    className='my-2'
                    type='primary'
                    disabled={file.approved > 1}
                    onClick={() => {
                      if (!note) return message.warning("Please describe the issues.");
                      updateRequest(id, { index, note: note[index] }).then(res => {
                        message.success('Successfully submited! We will try to restore again.');
                      }).catch(err => {
                        message.warning("Something went wrong!");
                      })
                    }}>
                    Submit
                  </Button>
                </>}
                {(!isShipped(file) && !packagePrint()) && <><Button type='primary' className='mb-2' block size="large" icon={(!order.paymentId) ? <LockOutlined /> : <DownloadOutlined />} onClick={() => handleClick(file, index)}>
                  {(!order.paymentId) ? "Purchase to remove watermark" : "Download High Resolution"}
                </Button>
                  <Divider>Any Issues?</Divider>
                  <Input.TextArea
                    size='large'
                    placeholder="Please describe what needs to be fixed"
                    autoSize={{
                      maxRows: 5,
                      minRows: 2
                    }}
                    value={note[index]}
                    // disabled={file.approved > 1}
                    onChange={e => setNote(prev => ({
                      ...prev,
                      [index]: e.target.value
                    }))}
                  />
                  <Button
                    block
                    size="large"
                    className='my-2'
                    type='primary'
                    // disabled={file.approved > 1}
                    onClick={() => {
                      if (!note) return message.warning("Please describe the issues.");
                      updateRequest(id, { index, note: note[index] }).then(res => {
                        message.success('Successfully submited! We will try to restore again.');
                      }).catch(err => {
                        message.warning("Something went wrong!");
                      })
                    }}>
                    Submit
                  </Button>
                </>}
              </div>
            </div>
          </Col>) : <h1 className='text-2xl'>Order Not Found!</h1>}
          <Col span={24}>
            <p className='text-center mt-2 text-gray-400'>
              Any Issues? <a href="mailto:hello@prophotos.ai">Let us Know.</a>
            </p>
          </Col>
        </Row>
      </div>
      <Modal
        title="Print Settings"
        open={isPrStModalOpen}
        onCancel={() => {
          setIsPrStModalOpen(false);
        }}
        width={900}
        maskClosable={false}
        afterClose={() => {
          console.log('here', order.status);
          if ((order.status == ORDER_STATUS.PendingApprove && !order.fileList.filter(file => !(file.refunded > 0)).some(file => file.approved < PHOTO_STATE.Printing))) {
            console.log('here');
            // return;
            setPrinting(true);
            printingPackage(order._id).then(res => {
              getOrder();
            }).catch(err => {
              console.log(err);
              if (err.response?.data?.message)
                message.warning(err.response.data.message);
            }).finally(() => {
              setPrinting(false);
            });
          }
        }}
        footer={[
          // <Button key="handleRotate1" icon={<RotateRightOutlined />} title="Rotate Photo" onClick={() => handleRotate(selFile.orderId, selFile.index, 90)}></Button>,
          // <Button key="handleRotate2" icon={<RotateLeftOutlined />} title="Rotate Photo" onClick={() => handleRotate(selFile.orderId, selFile.index, -90)}></Button>,
          <Radio.Group key='landscape' options={[
            { label: "Landscape", value: true },
            { label: "Portrait", value: false },
          ]} onChange={({ target: { value } }) => {
            console.log(value);
            setLandscape(value);
            savePhotoDir(selFile.orderId, { index: selFile.index, landscape: value }).then(res => {
              // console.log(res);
              getOrder();
            }).catch(err => console.log(err));
            let tmp = [];
            for (const i of [1, 2, 3, 4, 5]) {
              if (selFile[`print${i}`] > 0) {
                // console.log(`print${i}`, selFile[`print${i}`])
                tmp.push({
                  ...centerCrop(
                    makeAspectCrop(
                      {
                        // You don't need to pass a complete crop into
                        // makeAspectCrop or centerCrop.
                        unit: '%',
                        width: 100,
                      },
                      (Number(PRINT_SIZES[`print${i}`].split('x')[value ? 1 : 0]) / Number(PRINT_SIZES[`print${i}`].split('x')[value ? 0 : 1])),
                      selFile.dimension.w,
                      selFile.dimension.h
                    ),
                    selFile.dimension.w,
                    selFile.dimension.h
                  ),
                  paper: `print${i}`,
                  count: selFile[`print${i}`]
                });
              }
            }
            setCrp(pre => tmp.map((item, i) => (selFile.print[i] ? ({...selFile.print[i], ...item}) : item)));
            setPSetting([...tmp.map((item, i) => (selFile.print[i] ? ({...selFile.print[i], ...item}) : item))]);

          }} value={landscape} optionType="button" />,
          <Button className='ml-2' key="handleOk" loading={saving} onClick={() => {

            // let print = crp.map(item => ({
            //   ...item,
            // }));
            setSaving(true);
            savePrintSetting(selFile.orderId, { index: selFile.index, print: crp }).then(async res => {
              await getOrder();
              message.success("Successfully saved!");
              setIsPrStModalOpen(false);
            }).catch(err => console.log(err)).finally(() => setSaving(false));
          }}>Print</Button>,
          <Button key="handleShow" onClick={() => {
            setIsPrStModalOpen(false);
          }}>Close</Button>,
          // <Button key="handleCrop" danger onClick={handleCrop}>Crop</Button>
        ]}
      >
        <Tabs
          defaultActiveKey="0"
          type="card"
          size="small"

          items={[1, 2, 3, 4, 5].filter((item) => selFile[`print${item}`] > 0).map((_, i) => {
            return {
              label: PRINT_SIZES[`print${_}`],
              key: i,
              children: <Row gutter={[24, 24]}>
                <Col span={16} onWheel={(e) => handleWheel(e, i)}>
                  <ReactCrop
                    crop={pSetting[i]}
                    aspect={Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 0 : 1])}
                    onComplete={(c, pc) => {
                      // console.log(c, pc);
                      let tmp = [...crp];
                      tmp[i] = { ...tmp[i], ...pc };
                      setCrp(tmp);
                    }}
                    className='w-full relative'
                    data-value={'12'}
                    onChange={(c, pc) => {
                      // console.log(pc);
                      let tmp = [...pSetting];
                      tmp[i] = { ...tmp[i], ...pc };
                      setPSetting(tmp);
                    }}>
                    <img className='absolute top-0 mousewheel_wrapper w-full' key={`scale(${crp[i].scale})`} src={`${constants.SOCKET_URL}${selFile.result}`} style={{
                      transform: `scale(${crp[i].scale})`
                    }} />
                    <div
                      className='bg-white mousewheel_wrapper'
                      style={{
                        width: "100%",
                        aspectRatio: selFile.dimension.w / selFile.dimension.h
                      }}
                    >
                    </div>
                  </ReactCrop>
                </Col>
                <Col span={8}>
                  <h2>Print Preview</h2>
                  <img className='w-full border-4 border-solid border-white' src={previewImage(i)} />
                  <h2 className="text-center">x {selFile[`print${_}`]}</h2>

                  <Button
                    icon={<PiFrameCornersLight className='mr-.5' />}
                    className='flex items-center'
                    onClick={() => {
                      let crop = centerCrop(
                        makeAspectCrop(
                          {
                            // You don't need to pass a complete crop into
                            // makeAspectCrop or centerCrop.
                            unit: '%',
                            width: 100,
                          },
                          (Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 0 : 1])),
                          selFile.dimension.w,
                          selFile.dimension.h
                        ),
                        selFile.dimension.w,
                        selFile.dimension.h
                      );
                      if (crop.height == 100) {
                        let tmp = [...crp];
                        // console.log(e, e.deltaY, tmp[i].scale);
                        tmp[i] = { ...tmp[i], ...crop, scale: crop.width / 100 };
                        if (tmp[i].scale > 1) {
                          tmp[i].scale = 1;
                        } else if (tmp[i].scale <= 0.3) {
                          tmp[i].scale = 0.3;
                        }
                        setCrp(tmp);
                      } else {
                        let tmp = [...crp];
                        // console.log(e, e.deltaY, tmp[i].scale);
                        tmp[i] = { ...tmp[i], ...crop, scale: crop.height / 100 };
                        if (tmp[i].scale > 1) {
                          tmp[i].scale = 1;
                        } else if (tmp[i].scale <= 0.3) {
                          tmp[i].scale = 0.3;
                        }
                        setCrp(tmp);
                      }
                    }}
                  >Fit to Frame</Button>
                </Col>
              </Row>,
            };
          })}
        />
      </Modal>
    </PublicLayout>
  )
}

export default Result;