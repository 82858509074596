import React from 'react'
import { Steps, message } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import bg from '../../../../assets/images/album-bg.webp';
import { updateOrderDetail } from '../../../../redux/order/orderSlice';

function Header() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDarkMode = useSelector(state => state.app.isDarkMode);
  const orderData = useSelector(state => state.order);

  const onChange = (value) => {
    console.log('onChange:', value);
    updateData('step', value);
    if (value == 2 && orderData.fileList.length == 0) {
      message.warning("Please upload your photos");
      return navigate(`/order/step${2}`);
    }
    navigate(`/order/step${value + 1}`);
  };

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }
  // animate-bg 
  return (
    <div className="text-center py-10 relative bg-cover" style={{ backgroundImage: `url(${bg})` }}>
      <div className={classNames("absolute top-0 left-0 w-full h-full", isDarkMode ? "bg-[#000B]" : "bg-[#FFFB]")}></div>
      <div className='relative'>
        <h1>Get Your Photos restored now!</h1>
        <h3>Follow these 3 easy steps</h3>
        <div className="max-w-xl mx-auto mt-6 px-4">
          <Steps
            current={orderData.step}
            // type="navigation"
            responsive={false}
            onChange={onChange}
            direction="horizontal"
            items={[
              {
                title: 'Name & Email',
              },
              {
                title: 'Upload Photos',
              },
              {
                title: 'Print Settings',
                disabled: orderData.fileList.length == 0,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default Header