import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Form, Input, Row, message } from 'antd';
import classNames from 'classnames';
import { PiArrowFatRightFill } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import { CheckCircleFilled, CheckCircleTwoTone } from '@ant-design/icons';
import Header from './Partials/Header';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { saveUser } from '../../../services/authAPI';
import { checkPromo } from '../../../services/planAPI';

function Step1() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isDarkMode = useSelector(state => state.app.isDarkMode);

  const orderData = useSelector(state => state.order);

  const [form] = Form.useForm();
  const [option, setOption] = useState(orderData.option);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkPromo(location.search).then(({ data }) => {
      updateData('coupon', data.coupon);
    }).catch(err => console.log(err)).finally(() => {
    });
    window.scrollTo(0, 0);
    updateData('step', 0);
  }, []);

  // useEffect(() => {
  //   if (orderData.name && orderData.email) {
  //     onFinish({ name: orderData.name, email: orderData.email });
  //   }
  // }, [orderData]);

  useEffect(() => {
    updateData('option', option);
    updateData('fileList', []);
  }, [option]);

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  const onFinish = (values) => {
    setLoading(true);
    saveUser(values, location.search).then(({ data }) => {
      updateData('step', 1);
      updateData('coupon', data.coupon);
      navigate('/order/step2');
    }).catch(err => {
      message.error("Something went wrong!");
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <PublicLayout>
      <Header />
      <div className="max-w-4xl mx-auto w-full p-4 text-center">
        <Form
          name="register-user"
          form={form}
          className="form"
          scrollToFirstError
          initialValues={{
            name: orderData.name,
            email: orderData.email,
          }}
          onFinish={onFinish}
        >
          <section className='my-4'>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <h1 className='text-xl md:text-2xl lg:text-3xl'>What's your name?</h1>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your First & Last Name!',
                    },
                  ]}
                >
                  <Input size='large' placeholder='First & last name' value={orderData.name} onChange={(e) => updateData('name', e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
          </section>
          <Divider className='my-16' />
          <section className="my-4">
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <h1 className='text-xl md:text-2xl lg:text-3xl max-w-2xl mx-auto'>Where would you like us to email your restored photos?</h1>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input size="large" placeholder='Your Best Email Address' value={orderData.email} onChange={(e) => updateData('email', e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
          </section>
          <Divider className='my-16' />
          <section className="my-4">
            <Row gutter={[12, 12]} justify="center" className='items-stretch'>
              <Col span={24}>
                <h1 className='text-xl md:text-2xl lg:text-3xl max-w-2xl mx-auto'>Would you like to upload your photos now{/*  or send them to us later */}?</h1>
              </Col>
              <Col xs={22} sm={12} md={8}>
                <Card onClick={() => setOption('ready')} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (option == 'ready' && isDarkMode) && 'border-purple-700 bg-gray-900', (option == 'ready' && !isDarkMode) && "border-purple-700 bg-purple-50", (option != "ready" && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                  <div className='absolute top-5 left-5 sm:left-auto sm:right-5'><CheckCircleTwoTone style={{
                    fontSize: 20
                  }} twoToneColor={option == 'ready' ? '#0F0' : '#AAA'} /></div>
                  <div className='flex flex-col justify-between items-center h-full pt-4'>
                    <h2>I'm ready to upload them!</h2>
                    <img src="/imgs/photo-camera-svgrepo-com.svg" alt="ready" className='w-20' />
                    <p>You will be asked to upload your photos on Step 2</p>
                  </div>
                </Card>
              </Col>
              {/* <Col xs={22} sm={12} md={8}>
                <Card onClick={() => setOption('later')} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (option == 'later' && isDarkMode) && 'border-orange-500 bg-gray-900', (option == 'later' && !isDarkMode) && "border-orange-500 bg-orange-50", (option != "later" && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                  <div className='absolute top-5 left-5 sm:left-auto sm:right-5'><CheckCircleTwoTone style={{
                    fontSize: 20
                  }} twoToneColor={option == 'later' ? '#0F0' : '#AAA'} /></div>
                  <div className='flex flex-col justify-between items-center h-full pt-4'>
                    <h2>Send us your photos later via email</h2>
                    <svg className='my-3' width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M70.8519 30.2175L61.887 22.7995V12.2174C61.887 11.4088 61.2425 10.8228 60.4339 10.8228H47.4846L42.2697 6.46344C39.2814 4.00249 34.9806 4.01421 32.0041 6.48688L26.7892 10.8228H13.6641C13.2891 10.8111 12.9141 10.9517 12.6446 11.2096C12.375 11.4791 12.2227 11.8307 12.2227 12.2174V22.9518L3.42188 30.2175C3.08203 30.487 2.88281 30.8972 2.88281 31.3308H2.85938V62.3387C2.85938 66.7802 6.49221 70.4364 10.9336 70.4364H63.3402C67.7816 70.4364 71.4144 66.7684 71.4144 62.3387V31.3308H71.391C71.391 30.8972 71.1918 30.487 70.8519 30.2175ZM67.5472 31.2956L61.8987 35.5144V26.6081L67.5472 31.2956ZM33.8673 8.72517C35.7658 7.16657 38.508 7.16657 40.3947 8.74861L42.8908 10.8346H31.3712L33.8673 8.72517ZM58.969 13.7525V37.6941L40.1838 51.7098C38.3674 53.0574 35.8947 53.0574 34.09 51.7098L15.1641 37.5769V13.7525H58.969ZM12.2344 26.7487V35.3855L6.75002 31.2956L12.2344 26.7487ZM63.3402 67.5067H10.9336C8.1094 67.5067 5.78908 65.1513 5.78908 62.3387V34.2722L32.3087 54.0535C35.1799 56.1629 39.0822 56.1629 41.9533 54.0535L68.4847 34.2722V62.327C68.4847 65.163 66.1644 67.5067 63.3402 67.5067Z" fill="#C0BDB7"></path>
                      <path d="M43.781 25.2083C43.0878 24.5336 41.9324 24.4842 41.1859 25.126L35.249 30.3758L32.7427 27.9731C32.0495 27.2983 30.8941 27.2489 30.1475 27.8908C29.4365 28.582 29.4365 29.6846 30.1475 30.3922L33.9514 33.9963C34.2891 34.3419 34.7513 34.5229 35.249 34.5064C35.7467 34.5064 36.2088 34.309 36.5466 33.9963L43.7988 27.6275C44.5098 27.0186 44.5631 25.9818 43.9055 25.3235C43.8521 25.2741 43.8166 25.2412 43.781 25.2083Z" fill="#C0BDB7"></path>
                    </svg>
                    <p>We will send you instructions after you placed your order.</p>
                  </div>
                </Card>
              </Col> */}
              {/* <Col span={8}>
              <Card onClick={() => setOption('email')} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (option == 'email' && isDarkMode) && 'border-orange-500 bg-gray-900', (option == 'email' && !isDarkMode) && "border-orange-500 bg-orange-50", (option != "email" && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                <div className='absolute top-5 left-5 sm:left-auto sm:right-5'><CheckCircleTwoTone style={{
                  fontSize: 20
                }} twoToneColor={option == 'email' ? '#0F0' : '#AAA'} /></div>
                <div className='flex flex-col justify-between items-center h-full pt-4'>
                  <h2 className='mt-3'>Mail us your photos</h2>
                  <img src="/imgs/mail-in76-fill.png" alt="" className='w-[90px]' />
                  <p className='text-lg font-bold'>$15 per photo</p>
                </div>
              </Card>
            </Col> */}
            </Row>
          </section>
          <Divider className='my-16' />
          <section className='mt-4 mb-8'>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Button htmlType='submit' className='w-96 inline-flex items-center justify-center' size='large' type='primary' loading={loading}>Click to go to Step 2 &nbsp; <PiArrowFatRightFill /></Button>
              </Col>
            </Row>
          </section>
        </Form>
      </div>
    </PublicLayout>
  )
}

export default Step1;