import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, Divider, Form, Input, Row, message } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout'
import { CheckCircleFilled, CheckCircleTwoTone, LockOutlined } from '@ant-design/icons';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AddressElement, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CheckoutForm from './Partials/CheckoutForm';
import bg from '../../../assets/images/album-bg.webp';
import dbg from "../../../assets/images/bg.webp";
import constants, { SHIPPING_PRICING } from '../../../config/constants';
import { useSocket } from '../../../context/socket';
import { getPromo } from '../../../helpers';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { getOrdersById } from '../../../services/orderAPI';
// import useForm from '../../../Hooks/useForm';
import { checkPromo, createPaymentIntent, getOrderStatus, orderWithPayPal } from '../../../services/planAPI';

// const stripePromise = loadStripe(constants.stripePK);
function Checkout1() {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const socket = useSocket();

  const { id } = useParams();

  const isDarkMode = useSelector(state => state.app.isDarkMode);
  const orderData = useSelector(state => state.order);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cardError, setCardError] = useState("");

  const [payment, setPayment] = useState('card');
  const [isComplete, setIsComplete] = useState(false);
  const [delivery, setDelivery] = useState(orderData.delivery);

  const onFinish = (values) => {
    // console.log(values);
  };

  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 4989381, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    window.scrollTo(0, 0);
    if (id) {
      getOrder();
    }
  }, []);

  const getOrder = () => {
    getOrdersById(id).then((res) => {
      const { _id, createdAt, paymentId, refunded, status, updatedAt, userId, __v, ...rest } = res.data.order;
      if (paymentId) {
        message.warning("Already purchased!");
        navigate('/order/step1');
      } else {
        dispatch(updateOrderDetail({
          value: {
            orderId: _id,
            name: userId.name,
            email: userId.email,
            ...rest
          }
        }));
        form.setFieldsValue({
          name: userId.name,
          email: userId.email,
        });

        checkPromo(location.search).then(({ data }) => {
          updateData('coupon', data.coupon);
        }).catch(err => console.log(err));
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    updateData('delivery', delivery);
  }, [delivery]);

  useEffect(() => {
    if (!id && (orderData.fileList.length == 0 || !orderData.orderId)) {
      navigate('/order/step1');
    }
  }, [orderData]);

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  const totalPrice = () => {
    let price = orderData.fileList.reduce((accumulator, file) => {
      let subPrice = 0;
      subPrice += Number(file.colorize || 0) * 9.99;
      subPrice += (file.print1 || 0) * 10;
      subPrice += (file.print2 || 0) * 15;
      subPrice += (file.print3 || 0) * 24;
      subPrice += (file.print4 || 0) * 34;
      subPrice += (file.print5 || 0) * 49;
      return accumulator + subPrice;
    }, 0);
    let rPrice = orderData.artist == "top" ? 58 : 38;
    if (orderData.coupon == 'static') rPrice = 22;
    price += orderData.fileList.length * rPrice;
    price += orderData.promo1 ? (orderData.fileList.length * 29) : 0;
    price += orderData.promo2 ? (orderData.fileList.length * 19) : 0;
    price += orderData.fileList.length * (orderData.shippingDelay > 0 ? Number(`${orderData.shippingDelay - 1}9.99`) : 0);
    if (isShipped()) {
      price += orderData.fileList.length * (SHIPPING_PRICING[orderData.delivery].price);
    }
    if (orderData.fileList.length > 2 && orderData.fileList.length < 5) {
      price -= 20;
    } else if (orderData.fileList.length > 4 && orderData.fileList.length < 10) {
      price -= 40;
    } else if (orderData.fileList.length > 9) {
      price -= 100;
    }
    return [Number((price * (getPromo(orderData.coupon))).toFixed(2)), Number((price + (orderData.coupon == 'static' ? (16 * orderData.fileList.length) : 0)).toFixed(2))];
  }

  const isShipped = () => {
    return orderData.promo1 || orderData.promo2 || orderData.fileList.some(file => file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0);
  }

  const payNow = async () => {
    if (loading) return;
    if (isShipped() && !isComplete) {
      return message.warning("Please fill all billing info!");
    }
    form.validateFields().then(async (values) => {
      try {
        setLoading(true);
        let paymentMethod = await stripe?.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: orderData.name,
            email: orderData.email,
          },
        });

        if (paymentMethod?.error) {
          setCardError(paymentMethod.error.message);
          setLoading(false);
          return;
        }

        let res = await createPaymentIntent({
          paymentMethod: paymentMethod?.paymentMethod?.id,
          pm_type: paymentMethod?.paymentMethod?.card.brand,
          pm_last_four: paymentMethod?.paymentMethod?.card.last4,
          orderData,
        });

        const { paymentIntent, error } = await stripe.confirmCardPayment(res.data.clientSecret);

        if (error) {
          console.log(error, '-----------payment----------');
          setLoading(false);
          return message.error(error.message);
        }

        let paymentId = paymentIntent.id;

        window.gtag('event', 'conversion', {
          'send_to': 'AW-11469018582/7t47CKHFiY4ZENar7dwq',
          'transaction_id': paymentId,
        });

        setLoading(false);

      } catch (error) {
        console.log(error);
        setLoading(false);
        if (error.response?.data?.message) {
          message.error(error.response.data.message);
        } else if (error.message) {
          message.error(error.message);
        }
      }
    }).catch(err => { console.log(err) });
  }

  const createOrder = (data, actions) => {
    return orderWithPayPal(orderData.orderId, { orderData }).then(res => {
      console.log(res);
      return res.data.result.id
    });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return getOrderStatus(orderData.orderId).then(res => {
      let paymentId = res.data.result.id;
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11469018582/7t47CKHFiY4ZENar7dwq',
        'transaction_id': paymentId,
      });
      message.success("Successsfully purchased!");
      if (res.data.orderId) {
        navigate(`/order/${res.data.orderId}/result`);
      } else {
        navigate('/order/success');
      }
    });
  };

  useEffect(() => {
    if (socket) {
      socket.on("charge.succeeded", async data => {
        message.success("Successsfully purchased!");
        if (data.orderId) {
          navigate(`/order/${data.orderId}/result`);
        } else {
          navigate('/order/success');
        }
      });
    }
    return () => {
      if (socket) {
        socket.off('charge.succeeded');
      }
    }
  }, [socket]);

  return (
    <PublicLayout>
      <div className="text-center py-10 bg-cover relative" style={{ backgroundImage: `url(${bg})` }}>
        <div className={classNames("absolute top-0 left-0 w-full h-full", isDarkMode ? "bg-[#000B]" : "bg-[#FFFB]")}></div>
        <div className='relative'>
          <h1 className='my-5'>Checkout</h1>
          <div className='flex items-center justify-center md:hidden'>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 42 42" fill="none"><path d="M21 1.75146L35.3798 4.94696C36.1795 5.12546 36.75 5.83421 36.75 6.65496V24.1322C36.75 27.6427 34.9948 30.9222 32.074 32.8682L21 40.2515L9.92602 32.8682C7.00352 30.9205 5.25002 27.6427 5.25002 24.134V6.65496C5.25002 5.83421 5.82052 5.12546 6.62027 4.94696L21 1.75146ZM21 5.33721L8.75002 8.05846V24.1322C8.75002 26.472 9.91902 28.6577 11.8668 29.9562L21 36.0462L30.1333 29.9562C32.081 28.6577 33.25 26.4737 33.25 24.134V8.05846L21 5.33896V5.33721ZM28.791 14.39L31.2673 16.8645L20.1303 28.0015L12.705 20.5762L15.1795 18.1017L20.1285 23.0507L28.791 14.3882V14.39Z" fill="#F7780F"></path></svg>
            <p className='m-0 text-lg font-medium'>100% Money Back Guarantee</p>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto w-full p-4">
        <Row gutter={[24, 24]}>
          <Col xs={24} md={16}>
            <Form
              name="register"
              form={form}
              className="form"
              scrollToFirstError
              initialValues={{
                name: orderData.name,
                email: orderData.email,
              }}
              onFinish={onFinish}
            >
              <section>
                <h1 className="text-xl md:text-2xl lg:text-3xl my-6">Billing details</h1>

                <Row gutter={[24, 12]}>
                  {!isShipped() &&
                    <>
                      <Col span={24}>
                        <label htmlFor="name" className="font-medium"></label>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your First & Last Name!',
                            },
                          ]}
                        >
                          <Input size="large"
                            placeholder="First & last name"
                            id="name"
                            value={orderData.name}
                            onChange={(e) => { updateData('name', e.target.value) }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  }
                  <Col span={24}>
                    <label className='font-medium' htmlFor="email">Your Email Address</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                    // validateStatus={errors.email ? 'error' : ''}
                    // help={errors.email}
                    >
                      <Input size='large'
                        placeholder="Your Best Email Address"
                        value={orderData.email}
                        id='email'
                        onChange={(e) => { updateData('email', e.target.value) }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {isShipped() && <AddressElement options={{
                  mode: 'shipping',
                  defaultValues: {
                    name: orderData.name,
                    address: orderData.address,
                  }
                }}

                  onChange={(e) => {
                    // console.log(e);
                    setIsComplete(e.complete);
                    updateData('name', e.value.name);
                    updateData('address', e.value.address);
                  }} />}

              </section>

              {isShipped() && <section className="my-16 text-white">
                <Row gutter={[24, 24]} className='bg-cover rounded-2xl py-6 relative' align={"center"} style={{
                  backgroundImage: `url('${dbg}')`
                }}>
                  <div className="absolute top-0 left-0 w-full h-full bg-[#000a] rounded-2xl"></div>
                  <Col md={20} xs={24}>
                    <h1 className='text-xl md:text-3xl font-bold'>
                      How quickly would you like to receive your restorations?
                    </h1>
                    <p className="text-md md:text-xl font-gray-400 mb-0">We deliver from Monday to Saturday.</p>
                  </Col>
                </Row>
              </section>}

              {isShipped() && <section className="my-16">
                <Row gutter={[24, 24]} align={'center'}>
                  {Object.keys(SHIPPING_PRICING).map((key) => (
                    <Col xs={24} sm={12} md={6} key={key}>
                      <Card onClick={() => setDelivery(key)} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (delivery == key && isDarkMode) && 'border-purple-700 bg-gray-900', (delivery == key && !isDarkMode) && "border-purple-700 bg-purple-50", (delivery != key && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                        <div className='absolute top-5 left-5 md:left-auto md:right-5'><CheckCircleTwoTone className='text-lg' twoToneColor={delivery == key ? '#0F0' : '#AAA'} /></div>
                        <div className='flex flex-col justify-between items-center h-full pt-4'>
                          {/* <img src="/imgs/airplane-delivery-svgrepo-com.svg" className='w-16 sm:w-14 md:w-20' alt="" /> */}
                          <h1 className={classNames('text-lg sm:text-lg md:text-xl font-bold my-3', delivery == key ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>{SHIPPING_PRICING[key].title}</h1>
                          <h2 className='text-sm md:text-md text-orange-500'>{SHIPPING_PRICING[key].price > 0 ? `$${SHIPPING_PRICING[key].price} / PHOTO` : 'Free'}</h2>
                          <p className='font-bold'>{dayjs().add(SHIPPING_PRICING[key].range[1], 'day').format('dd MMM D')}</p>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </section>}

              <section className='my-16'>
                <h1 className="text-xl md:text-2xl lg:text-3xl my-6">Your order</h1>
                <div className="p-0.5 bg-purple-700"></div>
                <h1 className="text-lg md:text-xl lg:text-2xl my-4">Your Photo Restoration <span className="text-orange-500">x {orderData.fileList.length}</span></h1>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className={classNames("text-md", isDarkMode ? "bg-gray-700 text-gray-300" : "text-gray-700 bg-gray-200")}>
                    <tr>
                      <th scope="col" className="px-6 py-5">
                        PRODUCT
                      </th>
                      <th scope="col" className="px-6 py-5 text-center">
                        GTY
                      </th>
                      <th scope="col" className="px-6 py-5 text-right">
                        PRICE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.fileList.map((file, index) => <tr key={index} className={classNames("border-b border-0 border-solid border-gray-200", isDarkMode ? "bg-gray-800 border-gray-700 hover:bg-gray-900 text-gray-200" : "bg-white hover:bg-gray-50")}>
                      <th scope="row" className="px-6 py-4 whitespace-nowrap font-normal">
                        <div className="flex items-center">
                          {file.path ? <img src={`${constants.SOCKET_URL}${file.path}`} alt="" className="w-16 h-16 object-cover rounded mr-4" /> : <div className='w-16 h-16 bg-gray-200 rounded shadow items-center justify-center mr-4 inline-flex'>
                            <h1 className="font-bold text-orange-600 m-0">{index + 1}</h1>
                          </div>}
                          <div>
                            {file.colorize && <p className='my-1'><span className="font-bold">Colorization:</span> Yes</p>}
                            {file.print1 > 0 && <p className='my-1'><span className="font-bold">4x6 Print:</span> Yes</p>}
                            {file.print2 > 0 && <p className='my-1'><span className="font-bold">5x7 Print:</span> Yes</p>}
                            {file.print3 > 0 && <p className='my-1'><span className="font-bold">8x10 Print:</span> Yes</p>}
                            {file.print4 > 0 && <p className='my-1'><span className="font-bold">11x14 Print:</span> Yes</p>}
                            {file.print5 > 0 && <p className='my-1'><span className="font-bold">16x20 Print:</span> Yes</p>}
                          </div>
                        </div>
                      </th>
                      <td className="px-6 py-4 text-center">
                        {file.colorize && <p className='my-1'>1</p>}
                        {file.print1 > 0 && <p className='my-1'>{file.print1}</p>}
                        {file.print2 > 0 && <p className='my-1'>{file.print2}</p>}
                        {file.print3 > 0 && <p className='my-1'>{file.print3}</p>}
                        {file.print4 > 0 && <p className='my-1'>{file.print4}</p>}
                        {file.print5 > 0 && <p className='my-1'>{file.print5}</p>}
                      </td>
                      <td className="px-6 py-4 text-right">
                        {file.colorize && <p className='my-1'>$9.99</p>}
                        {file.print1 > 0 && <p className='my-1'>${file.print1 * 10}</p>}
                        {file.print2 > 0 && <p className='my-1'>${file.print2 * 15}</p>}
                        {file.print3 > 0 && <p className='my-1'>${file.print3 * 24}</p>}
                        {file.print4 > 0 && <p className='my-1'>${file.print4 * 34}</p>}
                        {file.print5 > 0 && <p className='my-1'>${file.print5 * 49}</p>}
                      </td>
                    </tr>)}
                    <tr className={classNames("border-b-2 border-0 border-solid border-purple-700", isDarkMode ? "bg-gray-800 text-gray-200" : "bg-white")}>
                      <th scope="row" className="px-6 py-4 font-normal whitespace-nowrap">
                        <div>
                          <p className='my-1'><span className="font-bold">Photo restoration artist:</span> {orderData.artist == 'top' ? "Head Artist" : "Experienced Artist"}</p>
                          {orderData.promo1 && <p className='my-1'><span className="font-bold">Print Package(1-8x10 & 2-5x7):</span> yes</p>}
                          {orderData.promo2 && <p className='my-1'><span className="font-bold">Wallet Size Print:</span> yes</p>}
                          <p className='my-1'><span className="font-bold">Processing speed:</span> {orderData.shippingDelay == 0 && "3 Days"}
                            {orderData.shippingDelay == 1 && "24 Hours"}
                          </p>
                          {isShipped() && <p className='my-1'><span className="font-bold">Shipping speed:</span> {SHIPPING_PRICING[orderData.delivery].title}</p>}
                          {orderData.fileList.length > 2 && <p className="my-1"><span className="font-bold">Discount: </span>{(orderData.fileList.length > 2 && orderData.fileList.length < 5) && 3}{(orderData.fileList.length >= 5 && orderData.fileList.length < 10) && 5}{(orderData.fileList.length >= 10) && 10} Photos</p>}
                        </div>
                      </th>
                      <td className="px-6 py-4 text-center">
                        <p className='my-1'>{orderData.fileList.length}</p>
                        {orderData.promo1 && <p className='my-1'>{orderData.fileList.length}</p>}
                        {orderData.promo2 && <p className='my-1'>{orderData.fileList.length}</p>}
                        <p className='my-1'>{orderData.fileList.length}</p>
                        {isShipped() && <p className='my-1'>{orderData.fileList.length}</p>}
                        {orderData.fileList.length > 2 && <p className='my-1'>1</p>}
                      </td>
                      <td className="px-6 py-4 text-right">
                        <p className='my-1'>{orderData.coupon == 'static' && <span className='line-through'>${orderData.fileList.length * 38}</span>} ${orderData.fileList.length * (orderData.coupon == "static" ? 22 : 38)}</p>
                        {orderData.promo1 && <p className='my-1'>${orderData.fileList.length * 29}</p>}
                        {orderData.promo2 && <p className='my-1'>${orderData.fileList.length * 19}</p>}
                        <p className='my-1'>${Number(orderData.fileList.length * (orderData.shippingDelay > 0 ? Number(`${orderData.shippingDelay - 1}9.99`) : 0)).toFixed(2)}</p>
                        {isShipped() && <p className='my-1'>${Number(orderData.fileList.length * (SHIPPING_PRICING[orderData.delivery].price)).toFixed(2)}</p>}
                        {orderData.fileList.length > 2 && <p className="my-1">-${(orderData.fileList.length > 2 && orderData.fileList.length < 5) && 20}{(orderData.fileList.length >= 5 && orderData.fileList.length < 10) && 40}{(orderData.fileList.length >= 10) && 100}</p>}
                      </td>
                    </tr>
                    <tr className={classNames("", isDarkMode ? "bg-gray-800 text-gray-200" : "bg-white")}>
                      <th scope="row" className="px-6 py-4 whitespace-nowrap">
                        <h2 className='text-xl'>Total</h2>
                      </th>
                      <td className="px-6 py-4 text-center">
                      </td>
                      <td className="px-6 py-4 text-right">
                        <h2 className='text-xl font-bold'>{orderData.coupon && <span className='font-bold line-through'>${totalPrice()[1]}</span>} <span className="text-orange-500">${totalPrice()[0]}</span></h2>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              {/* <section className={classNames("my-16 rounded-xl p-5", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
          <div className="flex items-center">
            <img src="/imgs/upsell-deskt-preview-300x300.png" alt="" className="w-28 md:w-32 mr-4 md:mr-6 shadow" />
            <div>
              <h2 className="text-lg md:text-xl lg:text-2xl">Would you like to add a set of prints?</h2>
              <p className='my-1'>Add a set of one 8x10 & two 5x7 archival photo prints for each photo. We print your photos on museum-quality photo paper backed by our 100 year no-fade guarantee. <span className="font-bold text-orange-500">Only $87</span></p>
            </div>
          </div>
          <div className='text-center md:ml-32 md:text-left mt-5'><Button shape='round' type='primary' className='mr-2' icon={<CheckCircleFilled style={{ color: true ? 'orange' : '#888' }} />}>Yes, please add a set of prints!</Button></div>
        </section> */}

              <section className='my-16'>
                <div className={classNames('p-5 rounded-xl', isDarkMode ? "bg-gray-800" : "bg-purple-50")}>
                  <div className='flex justify-between items-center cursor-pointer' onClick={() => setPayment('card')}>
                    <h3 className='mb-0 text-sm md:text-lg lg:text-xl flex items-center'>{payment == 'card' ? <FaRegCircleDot className='text-orange-500 mr-2' /> : <FaRegCircle className='mr-2' />} Credit/Debit Cards</h3>
                    <div>
                      <img src="/imgs/payments/amex.svg" className='w-8 md:w-10 m-1' alt="" />
                      <img src="/imgs/payments/discover.svg" className='w-8 md:w-10 m-1' alt="" />
                      <img src="/imgs/payments/visa.svg" className='w-8 md:w-10 m-1' alt="" />
                      <img src="/imgs/payments/mastercard.svg" className='w-8 md:w-10 m-1' alt="" />
                    </div>
                  </div>
                  {/* <Elements stripe={stripePromise} nonce="random-nonce"> */}
                  {/* <CheckoutForm isComplete={!isShipped() || isComplete} form={form} /> */}
                  {payment == 'card' && <div>
                    <CardElement
                      options={{
                        disableLink: true,
                        classes: {
                          base: "border border-solid py-3 px-4 rounded mt-4 border-gray-400 bg-white",
                          invalid: "border-red-500"
                        }
                      }}
                      // className={classNames("border border-solid py-3 px-4 rounded mt-4 border-gray-400 bg-white")}
                      onChange={(event) => {
                        if (event.error) {
                          setCardError(event.error.message);
                        } else {
                          setCardError(null);
                        }
                      }}
                    />
                    {cardError && (
                      <div className="text-sm text-red-500">{cardError}</div>
                    )}
                    <div className="text-center mt-6">
                      <Button
                        type="primary"
                        size='large'
                        htmlType='submit'
                        className='w-72 h-12 rounded-none mb-4'
                        icon={<LockOutlined />}
                        disabled={!stripe || cardError}
                        onClick={payNow}
                        block
                        loading={loading}
                      >PLACE MY ORDER</Button>
                    </div>
                  </div>}
                  {/* </Elements> */}
                  <div className='flex justify-between items-center my-4 cursor-pointer' onClick={() => setPayment('paypal')}>
                    <h3 className='mb-0 text-sm md:text-lg lg:text-xl flex items-center'>{payment == 'paypal' ? <FaRegCircleDot className='text-orange-500 mr-2' /> : <FaRegCircle className='mr-2' />} Paypal</h3>
                    <div>
                      <img src="/imgs/payments/paypal.png" className='w-8 md:w-10 m-1' alt="" />
                    </div>
                  </div>
                  {payment == 'paypal' && <div className="text-center mt-6">
                    <PayPalScriptProvider options={{ "client-id": constants.CLIENT_ID }}>
                      <PayPalButtons
                        style={{
                          layout: "vertical",
                          label: "pay",
                          height: 48
                        }}
                        fundingSource='paypal'
                        onClick={(data, actions) => {
                          if (isShipped() && !isComplete) {
                            message.warning("Please fill all billing info!");
                            return actions.reject();
                          }
                          return form.validateFields().then(async (values) => {
                            console.log(values);
                            return actions.resolve();
                          }).catch(err => {
                            message.warning("Please fill all billing info!");
                            return actions.reject();
                          });
                        }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                      />
                    </PayPalScriptProvider>
                  </div>}
                </div>
              </section>
            </Form>

            <section className="my-16">
              <div className={classNames("p-5 max-w-3xl mx-auto rounded-xl", isDarkMode ? "bg-gray-900" : "bg-gray-50")}>
                <div className="flex items-center justify-center">
                  <img src="/imgs/Vector.png" className='mx-1 md:mx-2' alt="" />
                  <p className={classNames("text-[11px] sm:text-sm font-bold my-0 mx-1 md:mx-2 text-center", isDarkMode ? "text-gray-400" : "text-gray-500")}>
                    FREE<br />SHIPPING
                  </p>
                  <img src="/imgs/Group-1.png" className='mx-1 md:mx-2' alt="" />
                  <p className={classNames("text-[11px] sm:text-sm font-bold my-0 mx-1 md:mx-2 text-center", isDarkMode ? "text-gray-400" : "text-gray-500")}>
                    100%<br />Money Back
                  </p>
                  <img src="/imgs/Vector-1.png" className='mx-1 md:mx-2' alt="" />
                  <p className={classNames("text-[11px] sm:text-sm font-bold my-0 mx-1 md:mx-2 text-center", isDarkMode ? "text-gray-400" : "text-gray-500")}>
                    SECURE<br />PAYMENT
                  </p>
                </div>
                <Divider>GUARANTEED SAFE CHECKOUT</Divider>
                <div className="flex items-center justify-around">
                  {/* <img className='w-10 sm:w-auto px-1' src="/imgs/payments/McAfee.png" alt="" /> */}
                  <img className='w-10 sm:w-auto px-1' src="/imgs/payments/stripe.png" alt="" />
                  <img className='w-10 sm:w-auto px-1' src="/imgs/payments/PayPal_logo.png" alt="" />
                  <img className='w-10 sm:w-auto px-1' src="/imgs/payments/visacard.png" alt="" />
                  <img className='w-10 sm:w-auto px-1' src="/imgs/payments/master-card.png" alt="" />
                  <img className='w-10 sm:w-auto px-1' src="/imgs/payments/american-p.png" alt="" />
                  {/* <img className='w-10 sm:w-auto px-1' src="/imgs/payments/discpver.png" alt="" /> */}
                </div>
              </div>
            </section>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <section className='my-8'>
              <div className={classNames('hidden md:block p-5 rounded-xl text-white', isDarkMode ? "from-orange-700 to-orange-500" : "from-orange-600 to-orange-400")}>
                {/* <h1 className="mb-0 font-bold text-2xl md:text-3xl lg:texl-4xl homenaje-regular text-center">
                  100% <span className='text-lg md:text-xl lg:text-2xl'>★ ★ ★ ★ ★</span> <br />
                  MONEY <br />
                  BACK <br />
                  <span className='text-xl md:text-2xl lg:text-3xl'>GUARANTEE</span>
                </h1> */}
                <img src="/imgs/guaranteed_img.png" alt="img" className='w-full' />
              </div>
            </section>
            <section className='my-16'>
              <div className={classNames("rounded-lg p-6 text-center", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
                <div className='text-center -mt-12'>
                  <img src="/imgs/test-cot-icon.png" alt="" />
                </div>
                <p className='mt-4'>"We’re living in the future! I had an old faded black and white photo of my grandparents wedding, and the full color version is mind-blowing."</p>
                <span className='text-orange-500 text-lg'>★ ★ ★ ★ ★</span>
                <div>
                  <Avatar src="/imgs/david.webp" /> <span className='font-bold'>- Mark C.</span>
                </div>
              </div>
            </section>
            <section className='my-16'>
              <div className={classNames("rounded-lg p-6 text-center", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
                <div className='text-center -mt-12'>
                  <img src="/imgs/test-cot-icon.png" alt="" />
                </div>
                <p className='mt-4'>"OMG! I never thought i’d be able to fix this photo of my mother from her childhood. This is amazing, the photo is brand new! I’m very impressed!"</p>
                <span className='text-orange-500 text-lg'>★ ★ ★ ★ ★</span>
                <div>
                  <Avatar src="/imgs/ashley.webp" /> <span className='font-bold'>- Ashley T.</span>
                </div>
              </div>
            </section>
            <section className='my-16'>
              <div className={classNames("rounded-lg p-6 text-center", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
                <div className='text-center -mt-12'>
                  <img src="/imgs/test-cot-icon.png" alt="" />
                </div>
                <p className='mt-4'>"I wanted to surprise my father with a photograph that was taken before he went to war. The photo was badly cracked and faded – now the photo is as good as new!"</p>
                <span className='text-orange-500 text-lg'>★ ★ ★ ★ ★</span>
                <div>
                  <Avatar src="/imgs/jason.webp" /> <span className='font-bold'>- Jason M.</span>
                </div>
              </div>
            </section>
            <section className='my-16'>
              <div className="flex justify-around">
                <div className='text-center'>
                  <h4>As seen on</h4>
                  <div>
                    <img className="mx-1 bg-white rounded-full" src="/imgs/ABC.png" alt="" />
                    <img className="mx-1" src="/imgs/NBC.png" alt="" />
                    <img className="mx-1" src="/imgs/Fox-News.png" alt="" />
                  </div>
                </div>
                {/* <div className='text-center'>
                  <h4>Trusted by</h4>
                  <div>
                    <img className="mx-1" src="/imgs/Group-654.png" alt="" />
                  </div>
                </div> */}
              </div>
            </section>
          </Col>
        </Row>
      </div>
    </PublicLayout>
  )
}

export default Checkout1